$(function() {
    //App.init() needs to go away. it is only here for the loading icon transition
    App.init();
    window.localStorage.removeItem('lead-list-context');

    var $loginBtn = $('.js-login-btn');
    var $loginOrgBtn = $('.js-login-org-btn');
    let $verifyMfaBtn = $('#verify-mfa-btn');
    let $createMfaBtn = $('#create-mfa-btn');
    let $verifyMfaSmsBtn = $('#verify-mfa-sms-btn');
    let $creatSmsMfaBtn = $('#create-sms-mfa-btn');
    let $resetMfaBtn = $('#reset-mfa-btn');

    $('#username').focus();

    $(document).keypress(function(e) {
        var keycode = e.keyCode ? e.keyCode : e.which;

        if (keycode === 13) {
            e.preventDefault();
            $loginBtn.click();
            $verifyMfaBtn.click();
            $verifyMfaSmsBtn.click();
        }
    });

    $loginBtn.click(function(e) {
        var inputFields = $('#username, #password');
        $loginBtn.button('loading');
        inputFields.prop('disabled', true);
        e.preventDefault();

        $.ajax({
            url: '/login',
            method: 'POST',
            data: {
                u: $('#username').val(),
                p: $('#password').val(),
                redirect_uri: $('input[name="redirect_uri"]').val(),
            },
            success: function(data, txt, xhr) {
                switch (data.status) {
                    case 1: window.location.href = data.redirect_uri;
                    break;

                    case 2: $('#error').text('Please login through your SSO provider');
                    break;

                    case 3: $('#error').text('User is not permitted to login directly');
                    break;

                    default: $('#error').text('Authentication failed');
                }

                if (data.status !== 1) {
                    $loginBtn.button('reset');
                    inputFields.val('').prop('disabled', false);
                }
            },
        });
    });

    $loginOrgBtn.click(function(e) {
        $loginOrgBtn.button('loading');
        e.preventDefault();
        $.ajax({
            url: '/login/authenticated',
            method: 'POST',
            data: {
                u: $('#username').val(),
                organization_id: $('#organization_id').val(),
                redirect_uri: $('input[name="redirect_uri"]').val(),
            },
            success: function(data) {
                window.location.href = data.redirect_uri;
            },
        });
    });

    $createMfaBtn.click(function(e) {
        $createMfaBtn.button('loading');
        e.preventDefault();

        $('#mfa-code-input').removeClass('has-error');
        $('#mfa-code').prop('disabled', true);
        $('#mfa-error').text('');

        $.ajax({
            url: '/login/mfa-setup',
            method: 'POST',
            data: {
                code: $('#mfa-code').val(),
                user_token: $('input[name="user_token"]').val()
            },
            success: function(data) {
                if (data.code_verified === 1) {
                    window.location.href = data.setup_complete_uri;
                } else {
                    $('#mfa-code-input').addClass('has-error');
                    $('#mfa-error').text('You have entered invalid code or it has expired');
                    $createMfaBtn.button('reset');
                    $('#mfa-code').val('').prop('disabled', false);
                }
            },
        });
    });

    $verifyMfaBtn.click(function(e) {
        $verifyMfaBtn.button('loading');
        e.preventDefault();

        $('#mfa-code-input').removeClass('has-error');
        $('#mfa-code').prop('disabled', true);
        $('#mfa-error').text('');

        $.ajax({
            url: '/login/mfa-verify',
            method: 'POST',
            data: {
                code: $('#mfa-code').val(),
                user_token: $('input[name="user_token"]').val(),
                redirect_uri: $('input[name="redirect_uri"]').val(),
            },
            success: function(data) {
                if (data.code_verified === 1) {
                    window.location.href = data.redirect_uri;
                } else {
                    $('#mfa-code-input').addClass('has-error');
                    $('#mfa-error').text('You have entered invalid code or it has expired');
                    $verifyMfaBtn.button('reset');
                    $('#mfa-code').val('').prop('disabled', false);
                }
            },
        });
    });

    $verifyMfaSmsBtn.click(function(e) {
        $verifyMfaSmsBtn.button('loading');
        e.preventDefault();

        $('#mfa-code-input').removeClass('has-error');
        $('#mfa-sms-code').prop('disabled', true);
        $('#mfa-error').text('');

        $.ajax({
            url: '/login/mfa-sms-verify',
            method: 'POST',
            data: {
                code: $('#mfa-sms-code').val(),
                redirect_uri: $('input[name="redirect_uri"]').val(),
            },
            success: function(data) {
                if (data.code_verified === 1) {
                    window.location.href = data.redirect_uri;
                } else {
                    $('#mfa-code-input').addClass('has-error');
                    $('#mfa-error').text('You have entered invalid code or it has expired');
                    $verifyMfaSmsBtn.button('reset');
                    $('#mfa-sms-code').val('').prop('disabled', false);
                }
            },
        });
    });

    $resetMfaBtn.click(function(e) {
        $resetMfaBtn.button('loading');
        e.preventDefault();

        $.ajax({
            url: '/login/mfa-reset-request',
            method: 'POST',
            success: function(data) {
                $('#mfa-reset-request-container').addClass('display-none');
                $('#mfa-reset-request-response-container').removeClass('display-none');
                $resetMfaBtn.button('reset');
            },
        });
    });

    $creatSmsMfaBtn.click(function(e) {
        $creatSmsMfaBtn.button('loading');
        e.preventDefault();

        $('#mfa-code-input').removeClass('has-error');
        $('#mfa-sms-code').prop('disabled', true);
        $('#mfa-error').text('');

        $.ajax({
            url: '/login/mfa-sms-setup',
            method: 'POST',
            data: {
                code: $('#mfa-sms-code').val()
            },
            success: function(data) {
                if (data.code_verified === 1) {
                    window.location.href = data.setup_complete_uri;
                } else {
                    $('#mfa-code-input').addClass('has-error');
                    $('#mfa-error').text('You have entered invalid code or it has expired');
                    $creatSmsMfaBtn.button('reset');
                    $('#mfa-sms-code').val('').prop('disabled', false);
                }
            },
        });
    });
});
